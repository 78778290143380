@import '~typeface-roboto/index.css';
@import 'styles/custom.scss';

body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: #fff;
}

.aprox-info {
  font-size: 0.875rem;
  color: $settle-gray-300;
  @media (max-width: 480px) {
    font-size: 0.875rem;
  }
}

.quote-info {
  padding-bottom: 0.9rem;
  padding-top: 0.7rem;
  align-items: center;
  font-size: 0.875rem;
  color: $settle-gray-300;
}

.question-mark {
  height: 18px;
  position: relative;
  top: -1px;
  left: 5px;
  background: #2e2e2e;
  display: inline-block;
  border-radius: 15px;
  color: white;
  text-align: center;
  line-height: 18px;
  width: 18px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
}

.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner-item {
  max-width: 50%;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}

.label-form {
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-weight: 500;
  color: $settle-gray-500;
}

.label-email {
  font-family: 'Roboto';
  font-size: 0.875rem;
  color: $settle-gray-300;
  line-height: 2.5rem;
}

.button-container {
  justify-content: center;
}

.button-container button {
  margin: 25px 0;
  font-size: 1.125rem;
  justify-content: center;
  font-weight: 500;
}


.form-container {
  width: 100%;
  opacity: 0;
  z-index: -1;
  transform: translateX(-400px);
  transition: all 0.4s;
  position: absolute;
  &.active-form {
    opacity: 1;
    z-index: 1;
    transform: translateX(0);
  }
}

.error {
  color: #f00;
  position: absolute;
  transform: translate3d(0px, -7px, 0px);
  @media (max-width: 480px) {
    transform: translate3d(0px, -19px, 0px);
  }
}

a {
  color: $main-color;
  text-decoration: none;
}

.terms-and-conds {
  font-size: 0.75rem;
  color: $settle-gray-400;
  text-align: center;
  margin: auto;
  line-height: 1rem;
  padding-top: 0.5rem;
}

.ctf-value {
  @media (max-width: 480px) {
    font-size: 0.95rem;
  }
}

// ------------- loading spinner -------------

$loadingSpinnerDiameter: 20px;
$loadingSpinnerStrokeWidth: 4px;
$loadingSpinnerStrokeColor: #fff;
$loadingSpinnerOpacity: 0.8;
$loadingSpinnerStrokeStyle: solid; // solid, dashed, dotted
$loadingSpinnerSpeed: 0.7s;

.loadingSpinner {
  border: $loadingSpinnerStrokeWidth $loadingSpinnerStrokeStyle $loadingSpinnerStrokeColor;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  width: $loadingSpinnerDiameter - ($loadingSpinnerStrokeWidth * 2);
  height: $loadingSpinnerDiameter - ($loadingSpinnerStrokeWidth * 2);
  opacity: $loadingSpinnerOpacity;
  border-radius: 50%;
  animation: loadingSpinner $loadingSpinnerSpeed infinite linear;
  -webkit-animation: loadingSpinner $loadingSpinnerSpeed infinite linear;
}

button .loadingSpinner {
  margin-top: -$loadingSpinnerDiameter / 2;
  margin-left: -$loadingSpinnerDiameter / 2;
  position: absolute;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
